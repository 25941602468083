.qrcodeImage {
    width: 10rem;
}

.productImg {
    width: 10rem;
    border-radius: 2rem;
    box-shadow: 0 0.1rem 0.3rem rgba(0, 0, 0, 0.5);
}

.qrcodeAndImgContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 1rem;
    gap: 1rem;
}

.qrcodeContainer {
    margin: 1rem 0;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.paymentInstruc {
    margin-bottom: 1rem;
    font-size: 0.9rem;
}

.productName {
    font-size: 1rem;
}

.aboutProduct {
    color: var(--component-color);
    font-weight: 500;
    font-size: 1.4rem;
    margin-bottom: 0.5rem;
}

.aboutContainer {
    text-align: left;
    width: 100%;
}

.aboutItem {
    font-size: 0.8rem;
}

@media screen and (min-width: 1024px) {

    .productImg {
        width: 17rem;
    }   

    .qrcodeAndImgContainer {
        flex-direction: row;
        gap: 2rem;
    }

    .qrcodeContainer {
        margin-top: 2rem;
    }
    
    .qrcodeImage {
        width: 19rem;
    }

    .paymentInstruc {
        font-size: 1.2rem;
    }

    .productName {
        font-size: 2rem;
    }

    .aboutProduct {
        font-size: 1.8rem;
    }

    .aboutItem {
        font-size: 1rem;
    }
}

@media screen and (min-width: 1500px) {
    
    .qrcodeContainer {
        margin-top: 2.5rem;
    }
    
    .qrcodeImage {
        width: 22rem;
    }

    .paymentInstruc {
        font-size: 1.5rem;
    }

    .qrcodeAndImgContainer {
        margin-top: 1.3rem;
    }

    .productImg {
        width: 20rem;
    }

    .productName {
        font-size: 2.5rem;
    }

    .aboutProduct {
        font-size: 2rem;
    }

    .aboutItem {
        font-size: 1.2rem;
    }
}