.background-banner {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-bottom: 3rem;
    width: 21rem;
    height: 14rem;
    background: linear-gradient(174.61deg, #041833 4.16%, #04244F 48%, #154580 96.76%);
    padding: 1rem;
    border-radius: 1rem;
    box-shadow: 0 0.3125rem 0.625rem rgba(0, 0, 0, 0.5);
}

.banner {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.banner h2 {
    font-size: 1.35rem;
    font-family: var(--main-font);
    margin-bottom: 1rem;
    font-weight: 700;
}

.banner .container-image .partners {
    background-color: white;
    width: 2.5rem;
    border-radius: 0.5rem;
    padding: 1.5rem;
    margin: 0.5rem;
    box-shadow: 0.6rem 0.3125rem 0.625rem rgba(0, 0, 0, 0.75);
}

.container-image {
    margin: 0.3rem 0.7rem;
}

@media screen and (min-width: 1024px) {
    .banner {
        margin-top: 3rem;
        margin-bottom: 4rem;
    }

    .banner h2 {
        font-size: 1.8rem;
    }

    .background-banner {
        width: 60rem;
        height: 18rem;
        align-items: center;
    }

    .banner .container-image .partners {
        background-color: white;
        width: 7rem;
    }
}

@media screen and (min-width: 1500px) {
    .banner {
        margin-top: 5rem;
        margin-bottom: 4rem;
    }

    .banner h2 {
        font-size: 2.2rem;
    }

    .background-banner {
        width: 86rem;
        height: 25rem;
    }

    .banner .container-image .partners {
        background-color: white;
        width: 12rem;
        margin: 2rem;
    }
}