@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100..900&display=swap');

:root {
    --main-font: "Montserrat", sans-serif;
}

.main {
    margin-top: 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: var(--main-deg);
    font-family: var(--main-font);
}

.main h1 {
    margin-top: 1rem;
    font-weight: 500;
    font-size: 1.5rem;
    text-align: center;
}

.main p {
    font-size: 0.8rem;
    margin-bottom: 1rem;
    font-weight: 400;
    padding: 1rem 2rem;
    width: 24.375;
    display: flex;
    justify-content: space-between;
    text-align:justify;

}

.main .mainPageImage {
    margin: 2rem 0;
    border-radius: 1rem;
    width: 22rem;
    box-shadow: 0 0.3125rem 0.625rem rgba(0, 0, 0, 0.5);
}

.main .text-content {
    margin: 0 0.3rem;
    margin-bottom: 1rem;
}

.onuOds {
    display: flex;
    align-items: center;
}

.goalsObjectives {
    background-color: var(--main-white);
    border-radius: 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1rem;
}

.odsImg {
    border-radius: 1rem;
    width: 5rem;
    margin: 0 1rem;
    margin-bottom: 1rem;
}

.aligner {
    display: flex;
    flex-direction: column;

}

.onuPilars {
    margin-top: 2rem;
    font-weight: 500;
    font-size: 1rem;
}

@media screen and (min-width: 1024px) {
    .main {
        flex-direction: row;
        justify-content: space-evenly;
    }

    .main h1 {
        font-size: 2rem;
    }

    .main p {
        font-size: 1.2rem;
    }

    .main .mainPageImage {
        border-radius: 1rem;
        width: 24rem;
    }

    .main .text-content {
        margin-bottom: 1rem;
    }

    .odsImg {
        border-radius: 1rem;
        width: 11rem;
        margin-bottom: 3rem;
    }

    .goalsObjectives {
        padding: 0.5rem;
        border-radius: 1.5rem;
    }

    .onuPilars {
        font-size: 1.5rem;
    }
}

@media screen and (min-width: 1500px) {
    .main {
        flex-direction: row;
        gap: 4rem;
    }

    .main h1 {
        font-size: 3rem;
    }

    .main p {
        font-size: 1.4rem;
    }

    .main .mainPageImage {
        border-radius: 2rem;
        width: 35rem;        
    }

    .main .text-content {
        margin-bottom: 1rem;
    }

    .odsImg {
        border-radius: 1rem;
        width: 14rem;
        margin-bottom: 3rem;
    }

    .goalsObjectives {
        padding: 1rem 4rem;
    }

    .onuPilars {
        font-size: 2.4rem;
    }
}