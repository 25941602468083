.allComponents {
    font-family: var(--main-font);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.shopTitle {
    padding: 2rem 0;
    text-align: center;
    width: 100%;
    color: var(--main-white);
    background: linear-gradient(174.61deg, #041833 4.16%, #04244F 48%, #154580 96.76%);
    font-size: 1.4rem;
}

@media screen and (min-width: 1024px) {

    .shopTitle {
        font-size: 2rem;
    }
}

@media screen and (min-width: 1500px) {

    .shopTitle {
        font-size: 2.8rem;
    }
}