.favcon {
    width: 5rem;
    height: 5rem;
}

.downloadContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.downloadIcons {
    display: flex;
    align-items: center;
}

.pdfViewer {
    width: 60%;
    height: 70%;
}