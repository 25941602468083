.dashAside {
    margin: 2rem 0;
    width: 30%;
}

.dashContainer {
    display: flex;
    // justify-content: space-between;
}

.dashListElement {
    font-family: var(--main-font);
    font-weight: 500;
    padding: 1rem;
}

.dashLink {
    text-decoration: none;
    color: var(--main-dark-color);
}

