.donutContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

}

.donutTitle {
    font-family: var(--main-font);
    font-size: 1.2rem;
}

@media screen and (min-width: 1024px)  {
    .donutContainer {
        width: 50%;
    }
}