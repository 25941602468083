:root {
    --dna-usp-color: #f8fafc;
}

.footer {
    background: var(--dna-usp-color);
    padding: 0.4rem;
    display: flex;
    justify-content: center;
}

.footer img {
    background-color: var(--dna-usp-color);
    border-radius: 1rem;
    padding: 0.5rem;
    width: 3rem;
}

@media screen and (min-width: 1024px) {
    .footer img {
        width: 6rem;
    }
} 

@media screen and (min-width: 1500px) {
    .footer img {
        width: 11rem;
    }
} 