.deskDashContainer {
    display: none;
}

@media screen and (min-width:1024px) {
    .deskDashContainer {
        display: flex;
        flex-direction: column;
        width: 70%;
        justify-content: center;
        margin: 2rem 0;
    }

    .deskFlexWrapper {
        display: flex;
    }

}