.shopContainer {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.productContainer {
    padding: 1rem 0;
    margin: 1rem 0;
    width: 95%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: var(--main-white);
    border-radius: 1rem;
    box-shadow: 0 0.1rem 0.625rem rgba(0, 0, 0, 0.5);
}

.informationContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 1rem;
}

.comingSoonImg {
    width: 25%;
    border-radius: 1rem;
    box-shadow: 0 0.1rem 0.3rem rgba(0, 0, 0, 0.5);
    margin: 1rem 0;
}

.shopDescription {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: justify;
    width: 80%;
}

.productTitle {
    font-size: 1.4rem;
}

.productDescriptionTitle {
    font-size: 1.2rem;
    margin: 1rem 0;
}

.productDescription {
    font-size: 0.7rem;
    margin-bottom: 1rem;
}

.buyNowContainer {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    &__onLogin {
        width: 100%;
        flex-direction: row;
    }
}

.salesTeamPar {
    display: none;
    
    &__block {
        font-size: 0.8rem;
        width: 30%;
        display: block;
    }
}

@media screen and (min-width: 1024px) {

    .productContainer {
        width: 60%;
    }

    .productTitle {
        font-size: 1.8rem;
        margin-bottom: 1rem;
    }

    .informationContainer {
        flex-direction: row;
        width: 85%;
        justify-content: space-evenly;
        margin-bottom: 1.5rem;
    }

    .productDescriptionTitle {
        font-size: 1.3rem;
    }

    .productDescription {
        font-size: 0.8rem;
        margin-top: 1.5rem;
        margin-bottom: 2rem;
    }

    .comingSoonImg {
        width: 30%;
    }

    .shopDescription {
        width: 50%;
    }

    .salesTeamPar__block {
        font-size: 0.9rem;
        width: 35%;
    }
}

@media screen and (min-width: 1500px) {

    .productContainer {
        width: 70%;
    }

    .informationContainer {
        flex-direction: row;
        width: 95%;
        margin-bottom: 2rem;
    }

    .comingSoonImg {
        width: 20%;
    }

    .shopDescription {
        width: 40%;
    }

    .productTitle {
        font-size: 2rem;
        margin-bottom: 1.5rem;
    }

    .productDescriptionTitle {
        font-size: 1.4rem;
    }

    .productDescription {
        font-size: 1rem;
        margin-top: 1rem;
        margin-bottom: 2.5rem;
    }

    .salesTeamPar__block {
        font-size: 1.2rem;
        width: 40%;
    }
}