:root {
    --input-color: #e4e4e4;
}

.input-container {
    align-items: center;
    text-align: center;
    margin: 2rem 0;
    padding: 0 3rem;
}

.contact {
    width: 100%;
    font-family: var(--main-font);
    font-size: 1.25rem;
    padding: 0.5rem 0;
    margin-bottom: 0.4rem;
}

.div-container {
    background-color: var(--input-color);
    padding: 1rem 2rem 2rem 2rem;
    border-radius: 2rem;
}

.input-container .div-container form {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
}

.buttonPadding {
    margin-top: 2rem;
}

@media screen and (min-width: 1024px) {
    .contact {
        font-size: 2.1rem;
        margin-bottom: 1.75rem;
    }

    .input-container {
        padding: 0 5rem;    
    }
}

@media screen and (min-width: 1500px) {
    .contact {
        font-size: 3rem;
        margin-bottom: 2.5rem;
    }

    .input-container {
        padding: 0 6rem;    
    }
}