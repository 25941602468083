.dashboards {
    display: flex;
    flex-direction: column;
    margin-top: 1rem;
    width: 70%;
}

@media screen and (min-width: 1024px) {
    .dashboards {
        display: none;
    }
}