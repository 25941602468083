:root {
    --component-color: #1400eb;
    --close-button-color: #002F52;
}

.modalBackground {
    position: fixed;
    left: 0rem;
    right: 0rem;
    top: 0rem;
    bottom: 0rem;
    background: rgba(101, 101, 101, 0.85);   
}

.modalMainWindow {
    position: fixed;
    padding: 1rem;
    background: var(--main-white);
    box-shadow: 0.25rem 0.25rem 1.5rem -0.25rem rgba(0, 0, 0, 0.15);
    border-radius: 1.5rem;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.modalButton {
    background: transparent;
    cursor: pointer;
    border: none;
    color: var(--close-button-color);
}

.modalTitleWrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.modalTitle {
    line-height: 3rem;
    font-weight: 700;
    font-size: 2rem;
    color: var(--component-color);
}

@media screen and (min-width: 1024px) {
    .modalMainWindow {
        padding: 2rem;
    }

    .modalTitle {
        font-size: 3rem;
    }
}

@media screen and (min-width: 1500px) {
    .modalMainWindow {
        padding: 3rem;
    }

    .modalTitle {
        font-size: 4rem;
    }
}