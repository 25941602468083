:root {
    --button-color: #6278f7;
    --hover-button-color: #7288ff;
    --hover-color: #95ffd4;
}

.button {
    color: var(--main-white);
    background-color: var(--button-color);
    font-family: var(--main-font);
    cursor: pointer;
    font-weight: 600;
    font-size: 0.75rem;
    padding: 0.75rem;
    // margin-top: 2rem;
    border-radius: 1.5rem;
    border: none;
    &:hover {
        color: var(--hover-color);
        background-color: var(--hover-button-color);
    }
}

@media screen and (min-width: 1024px) {
    .button {
        font-size: 0.85rem;
        padding: 0.8rem;
    }
}

@media screen and (min-width: 1500px) {
    .button {
        font-size: 1rem;
        padding: 1rem;
        border-radius: 2rem;
    }
}
