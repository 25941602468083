:root {
    --main-white: #FFFFFF;
    --main-color: #f6f6f6;
}

.preHeader {
    display: flex;
    flex-direction: column;
    font-family: var(--main-font);
    justify-content: center;
    background-color: var(--main-white);
}

.centeringContainer {
    background: linear-gradient(174.61deg, #041833 4.16%, #04244F 48%, #154580 96.76%);
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

.buildinnMessage {
    font-size: 0.7rem;
    color: var(--main-white);
    max-width: 10%;
    align-items: center;
}

.header {
    background-color: var(--main-white);
    font-family: var(--main-font);
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 0;
    max-width: 100%;
}

.iconParagraph {
    display: none;
}

.imgLogo {
    width: 100%;
}

.tagLink {
    width: 20%;
}

.icon {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    cursor: pointer;
}

.icons {
    justify-content: center;
    display: flex;
    align-items: center;
    width: 15%;
    gap: 20%;
}

.shopLink {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    text-decoration: none;
    color: black;
}

.shoppingBag {
    width: 70%;
    height: 70%;
}

.loginIcon {
    width: 70%;
    height: 70%;
}

.linkContainer {
    justify-content: center;
    width: 50%;
    display: flex;
    gap: 5%;
    &__hover {
        color: blue;
    }
}

.persLink {
    text-decoration: none;
    color: black;
}

.borderLinks {
    font-size: 0.6rem;
    border: 0.01rem solid;
    border-radius: 2rem;
    padding: 0.3rem 0.8rem;
    
}

@media screen and (min-width:1024px) {
    .borderLinks {
        font-size: 1rem;
        padding: 0.4rem 1.1rem;
    }

    .iconParagraph {
        display: flex;
        text-align: center;
    }

    .icon {
        font-size: 1.2rem;
    }

    .buildinnMessage {
        font-size: 1rem;
    }
}

@media screen and (min-width:1500px) {
    .borderLinks {
        font-size: 1.8rem;
        padding: 0.5rem 1.3rem;
        border-radius: 3rem;
    }

    .icon {
        font-size: 1.5rem;
    }

    .buildinnMessage {
        font-size: 1.2rem;
    }
}