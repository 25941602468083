.spectralContainer {
    margin-top: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin-bottom: 3rem;
}

.chartTitle {
    font-family: var(--main-font);
    font-size: 1.2rem;
}