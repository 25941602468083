.statsContainer {
    display: flex;
    flex-direction: column;
    font-family: var(--main-font);
    text-align: center;
    align-items: center;
    margin: 0 1rem;
    width: 90%;
    margin-bottom: 3rem;
}

.statsText {
    margin-bottom: 3rem;
    font-size: 1.2rem;
}

.individualStats {
    border: 0.1rem solid;
    display: flex;
    width: 100%;
    justify-content: space-between;
    padding: 1rem;
    margin: 0 2rem;
    border-top: none;
    &__border-top {
        border-top-left-radius: 1rem;
        border-top-right-radius: 1rem;
        border: 0.1rem solid;
    }
    &__border-bottom {
        border-bottom-left-radius: 1rem;
        border-bottom-right-radius: 1rem;
    }
}

.apiInfo {
    text-decoration: none;
    font-weight: 600;
    color: var(--main-dark-color);
}

.nameContainer {
    width: 100%;
}

@media screen and (min-width: 1024px) {
    .statsContainer {
        width: 50%;
    }
}