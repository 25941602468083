.loginImage {
    width: 15rem;
    border-radius: 2rem;
}

.registerContainer {
    display: flex;
    align-items: center;
    flex-direction: column;
    margin: 1.5rem 0;
}

.formModalContainer {
    display: flex;
    flex-direction: column;
}

.formStyle {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.registerButton {
    padding: 1rem 2rem;
    border-radius: 2rem;
    border: none;
    background-color: var(--main-color);
    cursor: pointer;
    &__bottom {
        margin-bottom: 1rem;
    }
}

.hLine {
    width: 30rem;
}

@media screen and (min-width:1024px) {
    .registerContainer {
        flex-direction: row;
        gap: 7rem;
    }

    .registerButton {
        font-size: 1.25rem;
    }

    .loginImage {
        width: 25rem;
    }
}

@media screen and (min-width:1500px) {
    .registerContainer {
        gap: 12rem;
    }

    .registerButton {
        font-size: 1.50rem;
    }

    .loginImage {
        width: 30rem;
    }
}