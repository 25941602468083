.input-text {
    display: flex;
    flex-direction: column;
    width: 12rem;
    &__message {
        width: 100%;
    }
}

.label {
    font-family: var(--main-font);
    font-size: 0.75rem;
    margin: 0.25rem;
}

.input {
    font-family: var(--main-font);
    font-size: 0.75rem;
    border-radius: 1rem;
    border: 0.01rem solid;
    padding: 0.25rem;
    margin-bottom: 0.25rem;
    &__message {
        padding: 3rem;
        text-align: justify;
    }
    &__boxShadow {
        box-shadow: 0rem 0.2rem 0.625rem rgba(0, 0, 0, 0.5);
    }
}

.select {
    display: flex;
    justify-content: center;
    align-items: center;
    color: grey;
}

.textPad {
    padding-left: 1rem;
}

.dropdown {
    display: none;
    font-size: 0.6rem;
    &__cond {
        display: flex;
        flex-direction: column;
    }

}

@media screen and (min-width: 1024px) {
    .label {
        font-size: 1.2rem;
    }

    .dropdown {
        font-size: 1rem;
    }

    .input {
        border-radius: 2rem;
        font-size: 1.25rem;
        padding: 0.5rem;
        &__message {
            padding: 5rem;
            font-size: 1.75rem;
        }
    }

    .input-text {
        padding-bottom: 0.25rem;
        width: 20rem;
        &__message {
            width: 100%;
        }
    }
}

@media screen and (min-width: 1500px) {
    .label {
        font-size: 1.75rem;
    }

    .input {
        border-radius: 2.5rem;
        font-size: 1.5rem;
        padding: 0.5rem;
        &__message {
            padding: 7rem;
            font-size: 2.35rem;
        }
    }

    .input-text {
        padding-bottom: 0.75rem;
        width: 30rem;
        &__message {
            width: 100%;
        }
    }
}