.titleDescriptionContainer {
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: center;
    align-items: center;
    margin: 2rem;
    padding: 1rem;
    border-radius: 1rem;
    font-family: var(--main-font);
    background-color: var(--main-white);
}

.titleDescriptionContainer h2 {
    font-size: 1.5rem;
    margin-bottom: 1rem;
    font-weight: 600;
}

.titleDescriptionContainer p {
    text-align: justify;
    font-size: 0.8rem;
}

@media screen and (min-width:1024px) {
    .titleDescriptionContainer {
        margin: 1rem;
    }

    .titleDescriptionContainer p {
        font-size: 1.2rem;
    }

    .titleDescriptionContainer h2 {
        font-size: 2rem;
    }
}

@media screen and (min-width:1500px) {
    .titleDescriptionContainer p {
        font-size: 1.4rem;
    }

    .titleDescriptionContainer h2 {
        font-size: 2.5rem;
    }
}