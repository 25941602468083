.groupInformation {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 1rem;
}

.trajectory {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    padding: 1rem;
}

.doubts {
    display: flex;
    flex-direction: column-reverse;
    justify-content: space-evenly;
    align-items: center;
    padding: 1rem;
}

.coffeeImage {
    width: 40%;
    border-radius: 2rem;
}

.doubtsImg {
    width: 40%;
    border-radius: 2rem;

}

.realibilityImg {
    width: 40%;
    border-radius: 2rem;
}

@media screen and ( min-width:1024px ) {

    .trajectory {
        flex-direction: row;
        justify-content: space-evenly;
    }

    .doubts {
        flex-direction: row;
        justify-content: space-evenly;
    }

    .coffeeImage {
        width: 25%;
        margin: 0 3rem;
    }

    .doubtsImg {
        width: 25%;
        margin: 0 3rem;
    }

    .realibilityImg {
        width: 25%;
        margin: 0 3rem;
    }
}